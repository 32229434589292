<template>
  <div id="divRoot" class="d-flex flex-column flex-root">

    <div class="my-6 center">
      <router-link to="/">
        <img
          :src="logoLight"
          class="logo-default max-h-40px"
        />
      </router-link>
    </div>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">

        <!-- begin:: Content -->
        <router-view />

      </div>
    </div>

    <div class="bottom-cookie" v-if="acceptedCookie === ''">
      <b-row class="text-center">
        <b-col cols="8" class="text-center m-5"><span style="font-size: 15px; color:white">{{ $t('cookie_description') }}</span></b-col>
        <b-col class="m-5">
          <v-btn text @click="setAcceptedCooke">
            <span style="font-size: 15px; color:white">OK</span>
          </v-btn>
        </b-col>
      </b-row>
		</div>

    <KTScrollTop></KTScrollTop>
  </div>
</template>

<style scoped>
  .bottom-cookie {
		position: fixed;
		z-index: 999;
		width: 100%;
		bottom: 0;
		background-color: black;
  }
</style>

<script>
import { mapGetters } from 'vuex';
import HtmlClass from '@/core/services/htmlclass.service';
import KTScrollTop from '@/view/layout/extras/ScrollTop';

import logoLight from '@/assets/logo/logo_light.png';

export default {
  name: 'AuthLayout',
  components: {
    KTScrollTop
  },
  computed: {
    ...mapGetters([
      'layoutConfig'
    ])
  },
  data() {
    return {
      logoLight,
      acceptedCookie: localStorage.getItem('eventboxAcceptedCookie') || '',
    }
  },
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setTimeout(() => {
      this.setIsMobile();
    }, 50);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setIsMobile();
    },
    setIsMobile() {
      const divRoot = document.getElementById('divRoot');
      if (divRoot) {
        const clientWidth = divRoot.clientWidth;
        const isMobile = this.$isMobile() === true || clientWidth < 560;
        this.$store.commit('set', ['isMobile', isMobile]);
      }
    },
    setAcceptedCooke() {
      this.acceptedCookie = new Date().getTime().toString();
      localStorage.setItem('eventboxAcceptedCookie', this.acceptedCookie);
    }
  }
};
</script>